@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@400;700&family=Montserrat:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Montserrat" sans-serif;
}

.login {
  height: 80vh;
  font-family: "Montserrat" sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on Hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.data_grid_box_container {
  font-size: 0.85rem;
  margin: 40px 0 0 0;
  height: 65vh;
}

.data_grid_box_container_entitlements {
  font-size: 0.85rem;
  margin: 40px 0 0 0;
  height: 20vh;
}

.data_grid_box_container_short {
  font-size: 0.85rem;
  margin: 40px 0 0 0;
  height: 45vh;
}

.data_grid_box_container_within_tabs {
  font-size: 0.85rem;
  margin: 0 0 0 0;
  height: 65vh;
}

.data_grid_box_container_short_within_tabs {
  font-size: 0.85rem;
  margin: 5px 0 0 0;
  height: 45vh;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-decoration: none;
  text-transform: uppercase;
}
.btn:hover {
  cursor: pointer;
}
.btn-full {
  width: 100%;
}
.btn-primary {
  color: #fff;
  background-color: #5792c9;
  border-color: #5792c9;
}

/* Hover state styles */
.btn.btn-primary:hover {
  color: #fff;
  background-color: #1688da;
  border-color: #1688da;
}

/* Focus state styles */
.btn.btn-primary:focus {
  color: #fff;
  background-color: #1688da;
  border-color: #1688da;
  box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.5);
  outline: none; /* Remove default focus outline */
}

.btn-outline-primary {
  border: 1px solid #5792c9; /* Border color for outline */
  color: #5792c9; /* Text color */
  background-color: transparent; /* Transparent background */
}

.btn-danger {
  color: #fff;
  background-color: #980000;
  border-color: #980000;
  margin-right: 1rem;
}

/* Hover state styles */
.btn.btn-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

/* Focus state styles */
.btn.btn-danger:focus {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  outline: none; /* Remove default focus outline */
}

.btn-outline-danger {
  border: 1px solid #980000; /* Border color for outline */
  color: #980000; /* Text color */
  background-color: transparent; /* Transparent background */
}

.btn-disabled {
  color: #545454;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-secondary {
  color: #f2f2f2;
  background-color: #f58320;
  border-color: #f58320;
}
.btn-success {
  color: #f2f2f2;
  background-color: #545454;
  border-color: #545454;
}

.btn-group {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
/* Margin between buttons */
.btn + .btn {
  margin-left: -1px;
}

.matched-name-cell {
  pointer-events: none; /* Disable pointer events */
  background-color: #ccc; /* Change background color to indicate disabled state */
  color: #999; /* Optionally change text color to indicate disabled state */
}
/* Border styling */
.border_style {
  position: relative;
  width: 250px;
  border: 1px solid black;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.border_text_style {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f7f8f9;
  padding: 0 10px;
}
.border_lines_style {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  border-top: 1px solid black;
}
/* Table styling */
.flex {
  display: flex;
  align-items: start;
}
.table-container {
  display: table;
  width: 100%;
}

.table-header {
  display: table-header-group;
  font-weight: bold;
}

.table-body {
  display: table-row-group;
}

.table-row {
  display: table-row;
}
.table-row img {
  display: flex;
}

.table-cell {
  display: table-cell;
  padding: 5px;
  vertical-align: top;
  text-align: left;
}
.table-cell-title {
  font-weight: bold;
}

.image-placeholder {
  padding: 8px;
  width: 50%;
  height: 100%;
}

.image-placeholder img {
  max-height: 100%;
  max-width: 100%;
  border-radius: 10px;
}
.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding-top: 10px;
}
.table-image-container {
  text-align: center;
}

.match {
  color: red;
}
