.login-wrapper {
  width: 90vw;
  margin: auto;
  border: 1px solid #52a8f5;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
}

.login-body {
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100%;
  width: 100%;
}

.login-image {
  border-right: 1px solid #52a8f5;
}

.login-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8% 30px;
  margin: 0 auto;
}
.login-form {
  width: 80%;
}

.image-overlay-container {
  position: relative;
  width: 100%;
}

.overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.text-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(28, 71, 103, 0.9);
}

.text {
  color: white;
  font-size: 16px;
  text-align: center;
}
